import React, { useEffect, useState } from 'react';
import { api } from '../Interceptor'
import PropertyImagesCarousal from '../Components/PropertyImagesCarousal'
import { Card, Col, Container, Row } from 'react-bootstrap'
import CustomLoader from '../Components/CustomLoader';
import useProperties from '../Utils/useProperties';
import moment from 'moment';
import ContactMap from '../Components/ContactMap';
import { facilities } from '../Utils/facility';

const Property = () => {
  const [loader, setLoader] = useState(false)
  const [property, setProperty] = useState([])
  const [location, setLocation] = useState({})
  const [properties] = useProperties()
  const getEstimatedTop = (date) => {
    const d = new Date(date)
    return moment(d).format('DD-MMM-YYYY')
  }
  const getProperty = async () => {
    setLoader(true)
    const projectname = decodeURIComponent(window.location.pathname.split('/').reverse()[0])
    const { data: { properties: prop } } = await api.method('get', `properties?project_name=${projectname}`)
    await setProperty({
      apiData: prop,
      manipulatedata: properties?.allProperties?.filter(({ project_name }) => project_name === projectname)
    })
    await setLoader(false)
    console.log(property?.manipulatedata[0]?.latitude)
    setLocation({
      latitude: property?.manipulatedata[0]?.latitude,
      longitude: property?.manipulatedata[0]?.longitude
    })

  }
  useEffect(() => {
    getProperty()
  }, [properties]);

  if (loader && !property.manipulatedata) {
    return <CustomLoader />
  }
  if (property?.manipulatedata) {
    return <div className='visited-property'>
      <PropertyImagesCarousal
        images={property?.manipulatedata[0].property_images}
        name={property?.manipulatedata[0]?.project_name}
      />
      <Container className='my-3 property-price-card'>
        <Card className='m-2'>
          <Card.Body>
            <div className='d-flex justify-content-between mx-5'>
              <div className="price-range">
                <h3 className='h3'>Price range (indicative)</h3>
                <p>
                  {`SGD ${new Intl.NumberFormat('en-SG').format(property?.manipulatedata[0].min_price)} ~ SGD ${new Intl.NumberFormat('en-SG').format(property?.manipulatedata[0].max_price)}`}
                </p>
              </div>
              <div className="sale-range">
                <h3 className='h3'>Land Size</h3>
                <p>
                  {new Intl.NumberFormat('en-SG').format(property?.manipulatedata[0].land_size)}
                </p>
              </div>

            </div>
            <div className='mx-5 gotomap'>
              See unit types and prices
            </div>

          </Card.Body>

        </Card>
        <Card className='m-2'>
          <Card.Body>
            <div className='d-flex flex-column mx-5'>
              <div className="price-range">
                <h3 className='h3'>Announcement</h3>
                <p>
                  {property?.manipulatedata[0].announcement ? property?.manipulatedata[0].announcement : "No Announcement"}
                </p>
              </div>
              <details className="sale-range" open={true} >
                <summary className='h3'>Summary</summary>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </p>
              </details>
            </div>
          </Card.Body>

        </Card>
        <Card className='m-2 py-4'>
          <Row className="mx-5">
            <Col lg={6} sm={12}>
              <div>
                <h4>
                  TOP date
                </h4>
                <p>
                  {getEstimatedTop(property?.manipulatedata[0].estimate_top)}
                </p>
              </div>
              <div>
                <h4>
                  Unit Size
                </h4>
                <p>
                  {`${property?.manipulatedata && property?.manipulatedata[0].units} .sqft`}
                </p>
              </div>
              <div>
                <h4>
                  Address
                </h4>
                <p>
                  {`${property?.manipulatedata && property?.manipulatedata[0].address} `}
                  <br />
                  <small className='text-primary'><h6 className='nunitoFont'><a href='#contact-map'>View On Map</a></h6></small>
                </p>
              </div>
              <div>
                <h4>
                  Project type
                </h4>
                <p>
                  {`${property?.manipulatedata && property?.manipulatedata[0].project_type} `}
                </p>
              </div>
            </Col>
            <Col lg={6} sm={12}>
              <div>
                <h4>
                  Bedrooms
                </h4>
                <p>
                  {`${property?.manipulatedata[0].bedrooms.toString() ? property?.manipulatedata[0].bedrooms.toString() : '---'} `}
                </p>
              </div>
              <div>
                <h4>
                  Tenure
                </h4>
                <p>
                  {`${property?.manipulatedata[0].tenure ? property?.manipulatedata[0].tenure : '---'} `}
                </p>
              </div>
              <div>
                <h4>
                  Architect
                </h4>
                <p>
                  {`${property?.manipulatedata[0].architect ? property?.manipulatedata[0].architect : '---'} `}
                </p>
              </div>
              <div>
                <h4>
                  Bathroom
                </h4>
                <p>
                  {`${property?.manipulatedata[0].bathrooms ? property?.manipulatedata[0].bathrooms : '---'} `}
                </p>
              </div>
            </Col>

          </Row>
        </Card>
        <Card className='m-2 py-4'>
          <Row className="mx-5">
            <h3>Facilities</h3>
            <Row className='my-3'>
              {property?.manipulatedata[0]?.facilities ? property?.manipulatedata[0].facilities.map((facility, id) => {

                return (
                  <Col lg={6}>
                    <h6 className='nunitoFont'> <img src={facilities[facility.trim().toLowerCase().replaceAll('\r', '').replace('\n', '')]} /> - {facility}</h6>
                  </Col>
                )
              })
                :
                <h4>---</h4>
              }
            </Row>
          </Row>
        </Card>
        <Card className='m-2 py-4'>
          <h2 className='nunitoFont p-3'>Varients</h2>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Bedroom type</th>
                  <th scope="col">Area (sq ft)</th>
                  <th scope="col">No. of units</th>
                  <th scope="col">Property Status</th>
                  <th scope="col">Min Price</th>
                  <th scope="col">Max Price</th>
                  <th scope="col">Floorplan</th>
                </tr>
              </thead>
              <tbody>
                {React.Children.toArray(
                  property?.apiData?.map((data, id) => {
                    return (
                      <>
                        <tr>
                          <th>{id + 1}</th>
                          <th scope="col">
                            {data?.bedrooms ?? '---'}
                          </th>
                          <th scope="col">
                            {data?.land_size ?? '---'}
                          </th>
                          <th>
                            {data?.units ?? '---'}
                          </th>
                          <th>
                            {data?.property_status ? data?.property_status : 'Call to enquire'}
                          </th>
                          <th>
                            {data?.min_price ? data?.min_price : 'Call to enquire'}
                          </th>
                          <th>
                            {data?.max_price ? data?.max_price : 'Call to enquire'}
                          </th>
                          <th>
                            floor-plan
                          </th>
                        </tr>
                      </>
                    )
                  })
                )}
              </tbody>
            </table>
          </div>
        </Card>
        <Card className='contact-map m-2 py-4' id="contact-map">
          {property?.manipulatedata && <ContactMap
            location={location}
          />}
        </Card>
      </Container>
    </div>;
  }
  return null
};

export default Property;
