import { faDollarSign, faHome, faPlayCircle, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Tour from '../../Assets/About/sideseaction.jpg'
const CounterBox = ({ fontName, count, title }) => {
  return (
    <div className="d-flex justify-content-around align-items-center">
      <div className="icon">
        <FontAwesomeIcon icon={fontName} className='display-6' />
      </div>
      <div className="side-details">
        <h5 className='m-0 nunitoFont'>{count}</h5>
        <small className='m-0'>{title}</small>
      </div>
    </div>
  )
}
const Mission = () => {
  return (
    <Container className='mission'>
      <div className="container-inner">
        <h2 className="m-auto text-center text-warning nunitoFont my-5">Our Mission Is To Find House</h2>
        <Row>
          <Col lg={7} md={7} sm={12}>
            <div className="nunitoFont">
              <div className="side-heading">
                <p className='m-0'>
                  Mauris ac consectetur ante, dapibus gravida tellus. Nullam aliquet eleifend dapibus.
                </p>
                <p className='m-0'>
                  Cras sagittis, ex euismod lacinia tempor.
                </p>
              </div>
            </div>
            <div className='my-5'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque quis ligula eu lectus vulputate porttitor sed feugiat nunc. Mauris ac consectetur ante, dapibus gravida tellus. Nullam aliquet eleifend dapibus. Cras sagittis, ex euismod lacinia tempor, lectus orci elementum augue, eget auctor metus ante sit amet velit.
            </div>
            <div className='my-5'>
              Maecenas quis viverra metus, et efficitur ligula. Nam congue augue et ex congue, sed luctus lectus congue. Integer convallis condimentum sem. Duis elementum tortor eget condimentum tempor. Praesent sollicitudin lectus ut pharetra pulvinar. Donec et libero ligula. Vivamus semper at orci at placerat.Placeat Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod libero amet, laborum qui nulla quae alias tempora.
            </div>
            <Row>
              <Col lg={4} md={4} sm={12} className='p-3'>
                <CounterBox fontName={faUser} count='80,123' title='Customers to date' />
              </Col>
              <Col lg={4} md={4} sm={12} className='p-3'>
                <CounterBox fontName={faHome} count='$74 Billion' title='In home sales' />

              </Col>
              <Col lg={4} md={4} sm={12} className='p-3'>
                <CounterBox fontName={faDollarSign} count='$468 Million' title='In Savings' />
              </Col>
            </Row>
          </Col>
          <Col lg={5} md={5} sm={12} className="right-side ">
            <div className="position-relative">
              <img src={Tour} alt="tour" className='img-fluid position-relatove' />
              {/* <FontAwesomeIcon icon={faPlayCircle} className='display-1 text-danger position-absolute' /> */}
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Mission;
