import { useEffect, useState } from "react";
import { api } from "../Interceptor";

const useTidBit = () => {
  const [type, setType] = useState('Residential')
  const [allContents, setAllContents] = useState([])
  const [content, setContent] = useState([])
  useEffect(async () => {
    const { data } = await api.method('get', `contents`)
    const filteredData = data.contents.filter((data) => data.content_category.title.toLowerCase() === type.toLowerCase())
    setAllContents(data.contents)
    setContent(filteredData)
  }, [])
  useEffect(() => {
    const data = allContents
    const filteredData = data.filter((data) => data.content_category.title.toLowerCase() === type.toLowerCase())
    setContent(filteredData)
  }, [type])

  return { type, setType, content };
};
export default useTidBit;
