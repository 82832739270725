import { useEffect, useState } from 'react';
import { api } from '../Interceptor'
const useProperties = () => {
  const [properties, setPoperties] = useState({})
  const [filter, setFilter] = useState({})
  const getData = async () => {
    const { data: { properties: allProperties } } = await api.method('get', 'properties')
    let uniqueProperty = []
    let uniqueProperties = []
    for (let i = 0; i < allProperties.length; i++) {
      const currentIteration = allProperties[i]
      const project = uniqueProperty.find(({ project_name }) => currentIteration.project_name == project_name)
      currentIteration.bedrooms = currentIteration.bedrooms ? [currentIteration.bedrooms] : []
      if (project) {
        project.bedrooms = [...project.bedrooms, ...currentIteration.bedrooms].sort()
        if (currentIteration.min_price < project.min_price) project.min_price = currentIteration.min_price
        if (currentIteration.min_price > project.max_price) project.max_price = currentIteration.min_price
        if (currentIteration.max_price > project.max_price) project.max_price = currentIteration.max_price
        continue
      }

      uniqueProperty.push(allProperties[i])
      uniqueProperties.push(allProperties[i])
    }
    const lanLong = uniqueProperties.map(({ latitude, longitude, _id, project_name }) => {
      return {
        _id,
        latitude,
        longitude,
        project_name
      }
    })
    setPoperties({
      location: lanLong,
      allProperties: uniqueProperties,
    })
  }
  useEffect(() => {
    getData()
  }, []);
  useEffect(() => {
    if (filter.length === 0) {
      return;
    }

    let _filterData = properties?.allProperties

    if (filter.search) {
      _filterData = _filterData.filter((prop) => {
        if (prop.project_name.toLowerCase().includes(filter.search.toLowerCase())) {
          return prop
        }
      })
    }
    if (filter.min_price) {
      _filterData = _filterData.filter((prop) => {
        if (filter?.min_price >= +(prop.min_price)) {
          return prop
        }
      })
    }
    console.log("_filterData", _filterData)
    if (filter.max_price) {
      _filterData = _filterData.filter((prop) => {
        if (filter?.max_price <= +(prop.max_price)) {
          return prop
        }
      })
    }
    if (filter.tenure) {
      _filterData = _filterData.filter((prop) => {
        if (prop.tenure === filter?.tenure) {
          return prop
        }
      })
    }
    if (filter.project_type) {
      _filterData = _filterData.filter((prop) => {
        if (prop.project_type === filter?.project_type) {
          return prop
        }
      })
    }
    if (filter.bedrooms) {
      _filterData = _filterData.filter((prop, id) => {
        if (prop.bedrooms.includes(filter.bedrooms)) {
          return prop
        }
        if (filter.bedrooms == '5+') {
          if (prop.bedrooms.filter((val) => val > 5)) {
            return prop
          }
        }
      })
    }
    if (filter.bathrooms) {
      _filterData = _filterData.filter((prop, id) => {
        if (prop.bathrooms === filter.bathrooms) {
          return prop
        }
        if (filter.bathrooms == '5+') {
          if (prop.bathrooms > 5) {
            return prop
          }
        }
      })
    }
    const lanLong = _filterData?.map(({ latitude, longitude, _id, project_name }) => {
      return {
        _id,
        latitude,
        longitude,
        project_name
      }
    })
    setPoperties({
      ...properties,
      location: lanLong,
      filteredData: _filterData
    })
  }, [filter]);


  return [properties, setPoperties, filter, setFilter];
};

export default useProperties;
