import React from 'react';
import { Card, Col } from 'react-bootstrap';

const TidbitCard = ({ imgPath, typeName, setType, type }) => {
  const classActive = type.toLowerCase() == typeName.toLowerCase() ? 'active-tidbit' : ''
  return <Col xl={4} lg={4} md={12} sm={12} >
    <Card className={`border tidbit-border ${classActive}`} onClick={() => setType(typeName.toLowerCase())}>
      <Card.Img src={imgPath} />
      <h5 className='text-center'>{typeName}</h5>
    </Card>
  </Col>


};

export default TidbitCard;
