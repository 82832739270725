import clubhouse from '../Assets/facilityicons/Clubhouse.webp'
import fittness from '../Assets/facilityicons/fittness.webp'
import Gym from '../Assets/facilityicons/gym.webp'
import pool from '../Assets/facilityicons/pool.webp'
import Lounge from '../Assets/facilityicons/lounge.webp'
import pavilion from '../Assets/facilityicons/pavilion.webp'
import playground from '../Assets/facilityicons/playground.webp'
import water_feature from '../Assets/facilityicons/water_feature.webp'
import yoga_corner from '../Assets/facilityicons/yoga_corner.webp'
export const facilities = {
  'veranda lounge': Lounge,
  "lap pool": pool,
  'shower point': water_feature,
  'outdoor fitness corner blissful lawn': Gym,
  "royal pavillion": pavilion,
  'hallmark pavillion': pavilion,
  'reading lounge': Lounge,
  'jacuzzi': pool,
  'bbq areatiffin lounge': Lounge,
  'sun deck': playground,
  "children's cove": yoga_corner,
  'herb gardensky lounge': Lounge,
  'observation lawn': fittness,
  "pet's haven": clubhouse

}