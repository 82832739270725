import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div className='container'>
      <h1 className="text-center">
        External Privacy Policy
      </h1>
      <h4>
        Policy Statement
      </h4>
      <p>This Privacy Policy (the “<b>Policy</b>”) applies to the collection, use and disclosure of an individual client/customer’s Personal Data (hereinafter defined) arising from goods and/or services offered by Property Roll Limited and its subsidiaries (“Property Roll” or “the Group”).</p>
      <h4>1. General</h4>
      <ul>
        <li>
          <b>1.1</b>
          This Policy provides information on the obligations and policies of Property Roll in respect of an individual client/consumer’s Personal Data. Property Roll undertakes to use reasonable efforts in applying, where practicable, those principles and the processes set out in this Policy to its operations.
        </li>
        <br />
        <li><b>1.2</b> Property Roll’s management, staff and representatives shall use reasonable endeavours to respect the confidentiality of and keep safe all Personal Data collected, stored, disclosed and used for, or on behalf of, the Group. Property Roll shall use reasonable endeavours to ensure all collection, storage, disclosure and usage of Personal Data by it shall be done in an appropriate manner and in accordance to the Personal Data Protection Act and this Policy.</li>
        <br />
        <li><b>1.3</b> <div>For the purposes of this Policy, in line with the provisions under the <i>Singapore Personal Data Protection Act 2012 (No. 26 of 2012)</i> (the "<b>Act</b>"), "<b>Personal Data</b>" shall mean data, whether true or not, about an individual who can be identified from that data; or from thatdata and other information which an organisation has or is likely to have access. Such Personal Data shall also refer to that which is already in the possession of Property Roll or that which shall be collected by Property Roll in future.</div></li>

      </ul>
      <h4>2. Statement of Practices</h4>
      <p> Managing the Collection, Use and Disclosure of Personal Data</p>
      <ul>
        <li><b>2.1</b>  Obtaining Consent: To the fullest extent required by the PDPA, before Property Roll collect, use or disclose your personal data, you will be notified of the purpose why it is doing so. Property Roll next obtains written confirmation from you on your expressed consent. As far as possible, Property Roll will not collect more personal data than necessary for the stated purpose. <br /> Under certain circumstances, Property Roll may assume deemed consent from you when you voluntarily provide your personal data for the stated purpose, e.g. when you submit your Personal Data for employment purposes.</li>
        <br />
        <li><b>2.2</b> Third-Party Consent: If you have a one-on-one meeting with Property Roll or do a transaction with Property Roll on behalf of another individual, you must first obtain consent from that individual in order for Property Roll to collect, use or disclose his/her personal data.</li>
        <br />
        <li><b>2.3</b>  Withdrawal of Consent: If you wish to withdraw consent, you should give Property Roll reasonable advance notice. You have to be aware, though, of the likely consequences of your withdrawal of consent, e.g. without your personal contact information Property Roll may not be able to inform you of future services offered by us or our clients. Your request for withdrawal of consent can take the form of an email or letter to Property Roll, or through the “UNSUB” feature in an online service.</li>
      </ul>
      <p>Types of Personal Data Collected</p>
      <ul>
        <li><b>2.4</b> As part of its day to day activities, Property Roll may collect from data subjects, through various means, including  via  referrals, introducers, roadshows, seminars, meetings, cookies, personal networks and social media, and any modes of communications and forms (e.g. survey forms, feedback forms) used by the Group from time to time, the following Personal Data:</li>
        <ul class="u-bullets">
          <li><div>Your   personal   information   such   as   full   name,  <b>last   3   digits   and   letter</b>  of NRIC/passport,   residential   address,   work   address,   e-mail   address,   telephone number,   mobile   phone   number,   date   of   birth,   gender,   dependants’   details, employment details, nationality, race, marital status, education level and other information necessary to fulfil special requests such as bereavement, emergency contact information, bank account/credit card details, photographs and images, and etc.</div></li>
          <li><div>Your  personal   government-issued   identification   document   numbers   such   as NRIC/FIN/Work Permit/birth certificate and passport numbers; only when <b>required</b> under the law or <b>necessary</b> to accurately establish or <b>verify</b> your identity to a high degree of fidelity.</div></li>
          <li>Other personal details (where relevant) when applying for an employee position: name, nationality, marital status,   languages spoken or written; professional and work   experience   (job   title,   occupation,   employment   history,   work   experience, Curriculum   Vitae;   education  and   professional   qualifications,  schools   attended, academic transcripts, membership of professional bodies).</li>
          <li>Property Roll may also collect non-personally identifiable information about you, such asuse of our website, internet protocol addresses, browser and computer system information, cookies, invisible pixels, web beacons, and aggregated data related to your use of our Platforms.</li>
        </ul>
        <br /><br />

        <p>Should you require more details on types of personal data we collect about you, you may visit our website:
          &nbsp;
          <a href="https://propertyroll.sg">propertyroll</a> &nbsp;
          or contact our Data Protection Officer, Mr Lim Yong Hock (the “<b>DPO</b>”) of Property Roll at the at &nbsp;
          <a target="_blank" href="mailto:enquiry@propertyroll.sg">enquiry@propertyroll.sg</a>. </p>

        <br />
        <p><i>Cookies</i></p>
        <p>Property Roll use “cookies” on our websites to track website visitorship, activity and experience. Cookies are files with a small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your computer’s hard drive, and most web browsers are designed to accept cookies. If you do not wish to receive any cookies, you may instruct your browser to refuse all cookies or to indicate them when a cookie is being sent. However, you may not be able to use some portions of Property Roll’s website if you do not to accept cookies.</p>
      </ul>
      <h3>Purpose of Collection of Personal Data</h3>
      <ul>
        <li>
          <b>2.5</b> The Personal Data mentioned in Paragraph 2.4 is collected for the following purposes:
        </li>
        <ul class="u-bullets">
          <li>
            Process job applications, recruitment and selection;
          </li>
          <li>
            Customer Care and account management;
          </li>
          <li>
            Prospecting for potential clients through various channels stated in Paragraph 2.4;
          </li>
          <li>
            Fact-finding of potential clients’ real estate needs;
          </li>
          <li>
            Planning and sourcing for interested clients based on the clients’ budget goals and real estate preferences;
          </li>
          <li>
            Completion of a sales transaction;
          </li>
          <li>
            Process facilities booking;
          </li>
          <li>
            Keeping clients informed of new real estate and investment opportunities that maybe relevant to them;
          </li>
          <li>
            Administration and servicing of a membership program;
          </li>
          <li>
            Analyse website visitors;
          </li>
          <li>
            Market research and analysis (using anonymised data);
          </li>
          <li>
            Carry out our obligations arising from any contracts entered into between you and us; and            </li>
          <li>
            Comply with legal obligations and regulatory requirements.            </li>
        </ul>
      </ul>
      <h3>Disclosure of Personal Data</h3>
      <ul>
        <li><b>2.6</b>  In order to carry out the functions described above, Property Roll may, from time to time, disclose your Personal Data between its Organisational entities.</li>
        <br />
        <li><b>2.7</b>  Without derogating from any of the above, Property Roll may also disclose your Personal Data to the following third parties:</li>
        <ul class="u-bullets">
          <li>
            Regulators and law enforcement officials e.g. Strata Title Board, Small Claims Tribunal, Building &amp; Construction Authority, Council for Estate Agencies, Singapore Police Force;
          </li>
          <li>
            Government agencies e.g. Ministry of Manpower, Ministry of Health, and etc;
          </li>
          <li>
            External advisors e.g. lawyers, auditors and accountants;
          </li>
          <li>
            Real estate developers;
          </li>
          <li>
            Financial institutions providing loans;
          </li>
          <li>
            Insurance companies;
          </li>
          <li>
            Third party service providers and consultants e.g. IT/Technical Support/ IT service providers, including but not limited to webhosting companies and cloud service providers;
          </li>
          <li>
            Credit, debit and charge card companies, banks and other entities processing payment; and            </li>
          <li>
            Any agent or subcontractor acting on Property Roll’ behalf for the provision of its services.            </li>
        </ul>
        <br />
        <li ><b>2.8</b> While Property Roll will only share your data with authorised persons on a ‘need to know basis’, Property Roll may disclose your Personal Data to the above mentioned parties also in the occurrence of any of the following events:</li>
        <ul class="u-bullets">
          <li>
            To the extent that Property Roll is required to do so by the law            </li>
          <li>
            In connection with any legal proceedings or prospective legal proceedings            </li>
          <li>
            To establish, exercise or defend Property Roll’s legal rights            </li>
          <li>
            To any person and/or entity for the purpose of processing such information on Property Roll’s behalf            </li>
          <li>
            To third parties who provide services to Property Roll or on its behalf            </li>
          <li>
            With your consent; and            </li>
          <li>
            For the purposes of disaster recovery.            </li>
        </ul>
      </ul>
      <h3><b></b> Optional Provision of Personal Data</h3>
      <ul>
        <li>
          <b>2.9</b> In some instances, you may also be requested to provide certain Personal Data that maybe used to better tailor the type of information presented to you. In most cases, this type of data is optional although, where the requested service is a personalised service, or provision of a service is dependent on your providing all requested data, failure to provide the requested data may prevent Property Roll from providing the service. This type of data includes, but is not limited to:
        </li>
        <ul class="u-bullets">
          <li>
            Lifestyle, hobbies and leisure activities            </li>
          <li>
            Family and household demographics            </li>
        </ul>
        <br />
        <li>
          <b>2.10</b> Under certain circumstances, telephone calls made to any of Property Roll’s service hotlines or inquiry telephone numbers will be recorded for the purposes of quality control, staff management and development. In such an event, by agreeing to this Policy, you hereby give your clear and unambiguous consent for the collection, use and disclosure of such Personal Data in accordance to this Policy.         </li>
      </ul>
      <h4>3. Transfer of Personal Data Overseas</h4>
      <ul>
        <li>
          <b>3.1</b>  Your Personal Data may be processed by Property Roll’s affiliates, agents, introducers and third parties providing services to organisations in jurisdictions outside of Singapore. If there is a need for Property Roll to transfer your personal data to another country, Property Roll will comply with the terms of the Personal Data Protection Act.         </li>
        <br />
        <li>
          <b>3.2</b> With regards to international properties, Property Roll will disclose your personal data to developers based in jurisdictions outside of Singapore. Those developers will be required, via contractual agreement with Property Roll, to undertake care of the personal data and comply to obligations equivalent to those required by the Singapore Personal Data Protection Act to accord similar levels of protection.         </li>
      </ul>
      <h4> 4. Accuracy of Personal Data</h4>
      <ul>
        <li>
          <b>4.1</b>  Where possible, Property Roll will validate data provided and take reasonable precautions using generally accepted practices and guidelines. This includes the use of checksum verification on some numeric fields such as account numbers or credit card numbers. In some instances, Property Roll is able to validate the data provided against pre-existing data held by it. In some cases, Property Roll is required to see original documentation before we may use the Personal Data such as with Personal Identifiers and/or proof of address. To assist in ensuring the accuracy of your Personal Data in the possession of Property Roll, please inform the Group of any updates of any parts of your Personal Data by sending a clearly worded email to the Data Protection Officer (DPO) at the email address at Paragraph 2.4. Further, Property Roll may, from time to time, conduct a verification exercise with you to update it on any changes to your Personal Data.         </li>
      </ul>
      <h4>5. Protection of Personal Data</h4>
      <ul>
        <li>
          <b>5.1</b>  Property Roll uses commercially reasonable physical, managerial and technical safeguards to protect and maintain the confidentiality and integrity of your Personal Data and will not knowingly allow unauthorised access, use, disclosure or otherwise to this data to anyone outside the Group, other than to you or as described in this Policy.         </li>
      </ul>
      <h4>6. Access and Correction of Personal Data</h4>
      <ul>
        <li>
          <b>6.1</b>  In accordance with the Act, you have the right to:
        </li>
        <ul>
          <li>a). Check whether the Group holds any Personal Data relating to you and, if so, obtain copies of such data; and</li>
          <li>
            b). Require the Group to correct any Personal Data relating to you which is inaccurate for the purpose for which it is being used.            </li>
        </ul>
        <br />
        <li>
          <b>6.2</b>   Property Roll reserves the right to charge a reasonable administrative fee in order to meet your requests under Clause 6.1(a).  Upon payment of the requisite fee under Clause 6.1(a) and/or receipt of your request under Clause 6.1(b).         </li>
        <br />
        <li>
          <b>6.3</b>  If you wish to verify the details you have submitted to Property Roll or if you wish to check on the manner in which Property Roll uses and processes your personal data, Property Roll’s security procedures mean that it may request proof of identity before revealing any information. This proof of identity will take the form of full details of name, contact number and NRIC or Passport or FIN number.  You must therefore keep this information safe as you will be responsible for any action which Property Roll takes in response to a request from someone using your details.          </li>
      </ul>
      <h4>7. Storage and Retention of Personal Data</h4>
      <ul>
        <li>
          <b>7.1</b>   Property Roll will delete, as reasonably possible, or otherwise anonymise any Personal Data in the event that the original purpose has been fulfilled or it is not required for any reasonable business or legal purposes of the Group. The Personal Data will be deleted from the Property Roll’s electronic, manual, and other filing systems in accordance with its internal policies and procedures and/or other agreements.         </li>
      </ul>
      <h4>8. Contacting You</h4>
      <ul>
        <li>
          <b>8.1</b> To the extent that any of the communication means which you have provided Property Roll with (which may include your telephone number and fax number) is/will be listed on the Do Not Call Registry (the “DNC”), ”), by checking the box on the Marketing Contact Form or  Privilege Member Registration Form, or by any other means of indication, you hereby grant Property Roll your clear and unambiguous consent to contact you using all of your communication means you have provided to the Organisation, including via voice calls, SMS, Whatsapp, MMS, fax or other similar communications applications or methods, for the purposes as stated in Paragraph 2.5.
        </li>
      </ul>
      <h4>9. Change of Policy</h4>
      <ul>
        <li>
          <b>9.1</b> Property Roll reserves the right to alter any of the clauses contained in this Policy in compliance with local legislation and/or to meet its global policy requirements, and for any other purpose deemed reasonably necessary by the Group.  You should revisit this page periodically for any changes. Changes to this policy are effective when they are posted on this page. If you do not agree to the modified terms, you should inform Property Roll as soon as possible of the terms to which you do not consent.  Pending such notice, if there is any inconsistency between these terms and the additional terms, the additional terms will prevail to the extent of the inconsistency.         </li>
      </ul>
      <h4>10. Governing Law</h4>
      <ul>
        <li>
          <b>10.1</b> This Policy is governed by and shall be construed in accordance with the laws of Singapore. You hereby submit to the non-exclusive jurisdiction of the Singapore courts.         </li>
      </ul>
      <h4>11. Miscellaneous</h4>
      <ul>
        <li>
          <b>11.1</b> This Policy only applies to the collection and use of Personal Data by Property Roll. It does not cover third-party sites to which Property Roll provide links. Property Roll does not share your Personal Data with third-party websites. Property Roll is not responsible for the privacy and conduct practices of these third-party websites. It is important that you review the Privacy Policy of every website that you visit. Property Roll has no control over, and are unable to assume any responsibility for, the content, privacy policies or practices of any third-party websites or services.         </li>
        <br />
        <li>
          <b>11.2</b>  Property Roll   will   not   sell   your   personal   information   to   any   third   party   without   your permission, but it cannot be responsible or held liable for the actions of third-party sites which you may have linked or been directed to Property Roll’s website.         </li>
      </ul>
      <h4>12. Contacting the Data Protection Officer</h4>
      <ul>
        <li>
          <b>12.1</b> You may write in to Property Roll, based on reasonable grounds, to request access to and/or correction of Personal Data relating to you, such Personal Data which is in the possession and control of Property Roll, Property Roll shall provide and/or correct that data in a reasonable time and manner in accordance with its standard procedures as stated in this Policy.         </li>
        <br />
        <li>
          <b>12.2</b> <div>In accordance with the Personal Data Protection Act, Property Roll has established a process for receiving and responding to any query or complaint that may arise with respect to the application of this Act. To ensure that Property Roll receives your complaints and enquiries, please send the same via email to the DPO at
            <a href="mailto:enquiry@propertyroll.sg">enquiry@propertyroll.sg</a> .</div>
        </li>
        <br />
        <li>
          <b>12.3</b> Should you not wish Property Roll to use your Personal Data for any of the purposes listed above, or not to receive promotional materials from Property Roll, you may opt out by sending a clearly worded email to the DPO.   Any applicable fee for processing the request will be advised, and Property Roll endeavours to fulfil your request within 30 days. If are unable to do so within 30 days, Property Roll will notify you of the additional time required.         </li>
      </ul>
      <p>Property Roll treats all such queries and complaints seriously and will deal with them confidentially and within reasonable time.</p>
      <small>Revision Date: 15 FEB 2022</small>
    </div>
  )
}

export default PrivacyPolicy