import React from 'react';
import { Carousel } from 'react-bootstrap';
import Placeholder from '../../Assets/placeholder.jpg';

const PropertyImagesCarousal = ({ images, onImageClick, name }) => {
    if (!images) {
        return null
    }
    if (images.length < 1) {
        return (
            <div className="propnx-carousel">
                <img
                    onClick={onImageClick}
                    className="d-block w-100"
                    src={Placeholder}
                    alt="First slide"
                />

            </div>
        )
    }
    if (images.length === 1) {
        return (
            <div className="propnx-carousel">
                <img
                    onClick={onImageClick}
                    className="d-block w-100"
                    src={images[0]}
                    alt="First slide"
                />

            </div>
        )
    }
    return <>

        <Carousel className='propnx-carousel' interval={null} variant="light">
            {
                React.Children.toArray(
                    images.map((image) => {
                        if (!image) {
                            return;
                        }
                        return (
                            <Carousel.Item>
                                <img
                                    onClick={onImageClick}
                                    className="d-block w-100"
                                    src={image}
                                    alt="First slide"
                                    onError={(event) => {
                                        event.target.src = Placeholder
                                    }}
                                />
                                {
                                    name
                                        ?
                                        <Carousel.Caption>
                                            <h4 className='nunitoFont text-light'>Home / Property</h4>
                                            <h1 className='nunitoFont text-light'>{name}</h1>
                                        </Carousel.Caption>
                                        :
                                        ''
                                }
                            </Carousel.Item>

                        )
                    })
                )
            }
        </Carousel>


    </>;
};

export default PropertyImagesCarousal;
