import React from 'react';
import Banner from '../Components/Banner';
import ContactInfo from '../Components/ContactInfo'

const Contact = () => {
    return (
        <>
            <Banner classname='aboutbanner' routesname={"Contact Us"} color={"light"} />
            <ContactInfo />
        </>
    )
};

export default Contact;
