import React from 'react';
import { Container } from 'react-bootstrap';
const Banner = ({ classname, routesname, color }) => {
  return (
    <section className={classname}>
      <Container className='py-5'>
        <p className={`text-${color}`}>Home / <span className='active'>{routesname}</span></p>
        <h1 className={`text-${color} nunitoFont`}>{routesname}</h1>
      </Container>
    </section>
  );
};

export default Banner;
