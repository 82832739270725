import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Card } from 'react-bootstrap';

const GlobalCards = ({ iconname, title, description, description2 }) => {
  return (
    <Card className='text-center why-choose-us-card'>
      <Card.Body>
        <div className="circle-container">
          <FontAwesomeIcon icon={iconname} className='text-danger display-1 my-5' />
        </div>
        <Card.Title>{title}</Card.Title>
        <Card.Text style={{fontSize:'15px'}}>
          {description} - {description2}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default GlobalCards;
