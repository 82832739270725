import React, { useState } from 'react';
import { Card, Row, Col, Badge } from 'react-bootstrap';
import CustomLoader from '../CustomLoader';
import PropertyImagesCarousal from '../PropertyImagesCarousal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-regular-svg-icons'
import { faBed, faHeart as heartSolid, faToilet } from '@fortawesome/free-solid-svg-icons'
import PropertyModal from '../Modal/PropertyModal';
import moment from 'moment';
import Price from '../Price';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const AllProperties = ({ allProperties }) => {
  const history = useHistory()
  const [selectedProperty, setSelectedProperty] = useState(null)
  const [isAdded, setAdded] = useState(false)
  const goToThisProperty = (name) => {
    history.push(`/property/${name}`)
  }
  const checkWhishList = (id) => {
    const ids = localStorage.getItem('whishList') ? JSON.parse(localStorage.getItem('whishList')) : [];
    for (let i = 0; i < ids.length; i++) {
      if (ids[i] === id) {
        return true
      }
    }
    return false
  }
  if (!allProperties) {
    return <CustomLoader />
  }
  return (
    <Row className='mb-4'>
      {
        React.Children.toArray(
          allProperties.map((prop) => {
            const { project_name, address, bedrooms, min, max, estimate_scs, _id, property_status, property_images, bathrooms } = prop
            const date = new Date(estimate_scs)

            return (
              <>
                <Col lg={6} className='my-2'>
                  <Card className='rounded property-card position-relative'>
                    <PropertyImagesCarousal onImageClick={() => goToThisProperty(project_name)} images={property_images} />
                    <div className='d-flex flex-wrap position-absolute prpnx-badge-group'>
                      {property_status ? <Badge bg="danger">{property_status.toUpperCase()}</Badge> : null}
                      {!isNaN(date.getTime()) ? <Badge bg="light" className='text-dark'>{moment(date).format('DD-MMM-YYYY')}</Badge> : null}
                    </div>
                    <Card.Body onClick={() => goToThisProperty(project_name)}>
                      <Card.Title>
                        <Price min={min} max={max} />
                        <div className="d-flex justify-content-between">
                          <h6 className='nunitoFont mb-0'><strong>{project_name}</strong></h6>
                          <FontAwesomeIcon icon={checkWhishList(_id) ? heartSolid : faHeart} color='#ff0000' onClick={(event) => {
                            event.stopPropagation()
                            const prevousID = JSON.parse(localStorage.getItem('whishList')) ?? []
                            const whistLitsIds = [... new Set([...prevousID, _id])]
                            setAdded(!isAdded)
                            localStorage.setItem('whishList', JSON.stringify(whistLitsIds))

                          }} />
                        </div>
                      </Card.Title>
                      <Card.Text>
                        {address ? `${address.substring(0, 35)}...` : <span>---</span>}
                      </Card.Text>
                      <div className='d-flex justify-content-between prop-details'>
                        {
                          bedrooms && bedrooms.length > 0 ?
                            <small>
                              <FontAwesomeIcon icon={faBed} className='mx-1 text-primary ' />
                              {bedrooms.toString()} bd.
                            </small>
                            :
                            null
                        }
                        {
                          bathrooms && bathrooms.length > 0 ?
                            <small>
                              <FontAwesomeIcon icon={faToilet} className='mx-1 text-primary ' />
                              {bathrooms} bt
                            </small> : null
                        }


                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </>
            )
          })
        )
      }
      <PropertyModal show={selectedProperty} handleClose={() => setSelectedProperty(null)} />
    </Row>
  )
};

export default AllProperties;
