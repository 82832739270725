import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Home from "./Pages/Home";
import Projects from "./Pages/Projects";
import Tidbit from "./Pages/Tidbit";
import Property from "./Pages/Property";
import PrivacyPolicy from "./Pages/PrivacyPolicy";

const routes = [
    {
        path: '/', component: Home, name: 'Home'
    },
    {
        path: '/projects', component: Projects, name: 'Projects'
    },
    {
        path: '/about', component: About, name: 'About'
    },
    {
        path: '/contact', component: Contact, name: "Contact"
    },
    {
        path: '/tidbit', component: Tidbit, name: "Tidbit"
    },
    {
        path: '/property/:project_name', component: Property, name: "Project", notInHeader: true
    },
    {
        path: '/privacy-policy', component: PrivacyPolicy, name: "Privacy Policy", notInHeader: true
    }
]

export default routes