import React from 'react';
import { BrowserRouter as Router, Switch } from "react-router-dom";
import Layout from './Layout/Layout'
import routes from './routes';
import './App.css';
import './theme.scss'
const App = () => {
  return (
    <Router>
      <Switch>
        {
          React.Children.toArray(
            routes.map(({ path, component }) => {
              return (
                <Layout path={path} exact component={component} />
              )
            })
          )
        }
      </Switch>
    </Router>
  );
}

export default App;