import React from 'react';
import Banner from '../Components/Banner';
import Mission from '../Components/Mission';
import WhyChooseUs from '../Components/WhyChooseUs';

const About = () => {
    return (
        <div>
            <Banner classname='aboutbanner' routesname={"About Us"} color={"light"} />
            <Mission />
            <WhyChooseUs />
        </div>
    );
};

export default About;
