import React, { useState } from 'react';
import { faEnvelopeSquare, faPencilAlt, faPhoneAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Container, Form, FormControl, InputGroup, Row, Spinner } from 'react-bootstrap';
import { api } from '../../Interceptor'
import ToastMessage from '../ToastMessage/ToastMessage';
const SubmitButton = ({ loader }) => {
  return (
    <Button variant="primary" type='submit' className='service'>{
      !loader ?
        "Get A Free Service" :
        <Spinner animation="border" role="status" style={{ width: '1rem', height: '1rem' }} variant={'light'}>
          <span className="visually-hidden">Loading...</span>
        </Spinner>
    }</Button>
  )
}
const ContactForm = () => {
  const [showMessage, setShowMessage] = useState(false)
  const [loader, setLoader] = useState(false)
  const [res, setRes] = useState({})
  const submitForm = async (event) => {
    event.preventDefault()
    setLoader(true)
    const formData = new FormData(event.target)
    let body = {}
    for (var pair of formData.entries()) {
      body = {
        ...body,
        [pair[0]]: pair[1]
      }
    }
    const { data } = await api.method('post', '/query', body)
      .catch(({ response }) => {
        if (response) {
          setLoader(false)
          setRes({
            type: 'danger',
            result: "failed",
            message: 'There is error in contact query please try again !'
          })
          setShowMessage(true)

        }
      })
    if (+data.status === 200) {
      event.target.reset()
      setLoader(false)
      setRes({
        type: 'success',
        result: "Submitted",
        message: 'Thank you for contacting with us our team will get back to you !'
      })
      setShowMessage(true)

    }
  }
  const closeToaster = () => {
    setShowMessage(false)
  }
  return (
    <div>
      <Container>
        <Form onSubmit={submitForm}>
          <Card className='p-5 contact-card'>
            <h2 className='text-capitalize nunitoFont'>Send Us A Message</h2>
            <Row className='my-3'>
              <Col lg={6} sm={12} md={6} className='my-4'>
                <InputGroup className="">
                  <FormControl
                    placeholder="Enter Your Name"
                    aria-label="Enter Your Name"
                    aria-describedby="basic-addon2"
                    name='full_name'
                    required
                  />
                  <InputGroup.Text id="basic-addon2" className='addon'><FontAwesomeIcon icon={faUser} /></InputGroup.Text>
                </InputGroup>
              </Col>
              <Col lg={6} sm={12} md={6} className='my-4'>
                <InputGroup className="">
                  <FormControl
                    placeholder="Enter Email Address"
                    aria-label="Enter Email Address"
                    aria-describedby="basic-addon2"
                    type='email'
                    name='email'
                    required
                  />
                  <InputGroup.Text id="basic-addon2" className='addon'><FontAwesomeIcon icon={faEnvelopeSquare} /></InputGroup.Text>
                </InputGroup>
              </Col>
              <Col lg={6} sm={12} md={6} className='my-4'>
                <InputGroup className="">
                  <FormControl
                    placeholder="Select Service Type"
                    aria-label="Select Service Type"
                    aria-describedby="basic-addon2"
                    name='service_type'

                  />
                  <InputGroup.Text id="basic-addon2" className='addon'><FontAwesomeIcon icon={faEnvelopeSquare} /></InputGroup.Text>
                </InputGroup>
              </Col>
              <Col lg={6} sm={12} md={6} className='my-4'>
                <InputGroup className="">
                  <FormControl
                    placeholder="Enter Phone Number"
                    aria-label="Enter Phone Number"
                    aria-describedby="basic-addon2"
                    type='number'
                    name='phone'
                    required
                  />
                  <InputGroup.Text id="basic-addon2" className='addon'><FontAwesomeIcon icon={faPhoneAlt} /></InputGroup.Text>
                </InputGroup>
              </Col>
              <Col lg={12} sm={12} md={12} className='my-4'>
                <InputGroup>
                  <FormControl as="textarea" placeholder='Enter Message' required name='message' />
                  <InputGroup.Text id="basic-addon2" className='addon'><FontAwesomeIcon icon={faPencilAlt} /></InputGroup.Text>
                </InputGroup>
              </Col>
              <Col className='d-flex justify-content-end'>
                <SubmitButton loader={loader} />
              </Col>
              <Col lg={12} sm={12} md={12} className='my-4'>
                <strong>Disclaimer</strong> : By entering your information and clicking 'Continue" you agree to our Terms of Use and Privacy Policy and that you may be contacted by phone, text message and email about your inquiry.

              </Col>
            </Row>
          </Card>
          <div className='d-flex justify-content-center align-items-center position-relative'>
            <ToastMessage showToaster={showMessage} closeToaster={closeToaster} {...res} />
          </div>

        </Form>
      </Container>
    </div>
  )

};

export default ContactForm;
