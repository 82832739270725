import React from 'react';
import { Toast } from 'react-bootstrap';

const ToastMessage = ({ showToaster, closeToaster, type, result, message }) => {
  return (
    <Toast show={showToaster} onClose={closeToaster} delay={5000} autohide bg={type} className='position-absolute contact-message-toaster'>
      <Toast.Header className={`bg-${type} text-light`}>
        <strong className="me-auto">Contact Query {result}</strong>
      </Toast.Header>
      <Toast.Body className='text-light'>
        <h6 className='nunitoFont'>{message}</h6>
      </Toast.Body>
    </Toast>
  );
};

export default ToastMessage;
