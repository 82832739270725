import React from 'react';
import useTidBit from '../Utils/useTidBit'
import { Row, Container, Col, Card, Accordion } from "react-bootstrap";
import TidbitCard from '../Components/TidbitCard'
import Residential from '../Assets/residential.png'
import Commercial from '../Assets/commercial.png'
import Industry from '../Assets/industry.png'
const Tidbit = () => {
    const { type, setType, content } = useTidBit()
    return <div className='tidbit m-3'>
        <Container>
            <Row>
                <TidbitCard typeName="Residential" imgPath={Residential} setType={setType} type={type} />
                <TidbitCard typeName="Commercial" imgPath={Commercial} setType={setType} type={type} />
                <TidbitCard typeName="Industry" imgPath={Industry} setType={setType} type={type} />
            </Row>
            <Row className='px-2 py-5'>
                <Accordion defaultActiveKey={['0']} alwaysOpen={true}>
                    {
                    content.length === 0 ?
                        "No Tidbit Found"
                        :
                        React.Children.toArray(

                            content.map((content, id) => {
                                return (
                                    <Accordion.Item eventKey={id}>
                                        <Accordion.Header>{content?.title} ({content?.content_category?.title})</Accordion.Header>
                                        <Accordion.Body
                                            dangerouslySetInnerHTML={{ __html: content.content.markup }}
                                        >

                                        </Accordion.Body>
                                    </Accordion.Item>

                                )
                            })
                        )
                    }
                </Accordion>
            </Row>
        </Container>
    </div>
};

export default Tidbit;
