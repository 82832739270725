import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import MarkerImage from '../../Assets/location-pin.png'
import PropertyModal from '../Modal/PropertyModal'
import { api } from '../../Interceptor';
import { useHistory } from 'react-router-dom';
const LoadingContainer = () => {
    return (
        <div className='gmap-wrapper-parent'>
            &nbsp;
        </div>
    )
}
const PropertiesMap = (props) => {
    const history = useHistory()
    const [property, setProperty] = useState(false)
    const { locations, google } = props
    if (!locations) {
        return null
    }
    const initialMapstate = {
        currentLocation: {
            lat: 1.3554481,
            lng: 103.881026,
        }
    }
    const getPrperttById = async (id) => {
        const { data: { property } } = await api.method('get', `property/${id}`)
        setProperty(property)
    }
    const goToThisProperty = (name) => {
        history.push(`/property/${name}`)
    }
    return (
        <Container>
            <Row className={`mb-4 gmap-wrapper ${props.extraClasses}`}>
                <Col>
                    <Map
                        google={google}
                        className={'map'}
                        initialCenter={initialMapstate?.currentLocation}
                        zoom={10}
                        style={{ borderRadius: '25px' }}
                        zoomControl={true}
                        disableDefaultUI={true}
                        gestureHandling={"greedy"}
                    >
                        {React.Children.toArray(
                            locations?.map((location) => {
                                const { latitude, longitude, project_name: name } = location
                                if (location) {
                                    return (
                                        <Marker
                                        
                                            position={{ lat: latitude, lng: longitude }}
                                            icon={{
                                                url: MarkerImage,
                                                anchor: new google.maps.Point(42, 42),
                                                scaledSize: new google.maps.Size(42, 42)
                                            }}
                                            onClick={() => goToThisProperty(name)}
                                        />
                                    )

                                }
                                return null
                            })
                        )}


                    </Map>

                </Col>
            </Row>
            {property ? <PropertyModal show={property} handleClose={() => setProperty(null)} /> : null}

        </Container>

    )
};
export default GoogleApiWrapper({
    apiKey: ('AIzaSyBv0qt6QTKqAGSynzrCQPy6gyLyEdE8HUY'),
    LoadingContainer: LoadingContainer

})(PropertiesMap)
