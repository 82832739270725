import { faBed, faToilet } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { Badge, Card, Col, Container, Row } from 'react-bootstrap';
import CustomLoader from '../Components/CustomLoader';
import Price from '../Components/Price';
import PropertyImagesCarousal from '../Components/PropertyImagesCarousal';
import useProperties from '../Utils/useProperties';
import Placeholder from '../Assets/placeholder.jpg';

const Projects = () => {
    let [properties] = useProperties()
    let prop = properties.allProperties
    if (!prop) {
        return <CustomLoader />
    }
    return (
        <Container>
            <h2 className='mt-5'> Projects </h2>
            <Row className='mb-4'>
                {
                    React.Children.toArray(
                        prop.map((prop, id) => {
                            const { project_name, address, bedrooms, min, max, estimate_scs, _id, property_status, property_images,
                                bathrooms
                            } = prop
                            const date = new Date(estimate_scs)

                            return (
                                <>
                                    <Col className='my-2' lg={4} sm={6}>
                                        <Card className='rounded property-card position-relative'>
                                            <PropertyImagesCarousal images={property_images?.length > 0 ? property_images : [Placeholder]} />
                                            {property_status ? <Badge bg="danger">{property_status.toUpperCase()}</Badge> : null}
                                            {!isNaN(date.getTime()) ? <Badge bg="light" className='text-dark'>{moment(date).format('DD-MMM-YYYY')}</Badge> : null}
                                            <Card.Body>
                                                <Card.Title>
                                                    <Price min={min} max={max} />
                                                    <div className="d-flex justify-content-between">
                                                        <h6 className='nunitoFont'><strong>{project_name}</strong></h6>

                                                    </div>
                                                </Card.Title>
                                                <Card.Text>
                                                    {address}
                                                </Card.Text>
                                                <div className='d-flex justify-content-between prop-details'>

                                                    {
                                                        bedrooms.length > 0 ?
                                                            <small>
                                                                <FontAwesomeIcon icon={faBed} className='mx-1 text-primary ' />
                                                                {bedrooms.toString()} bd.
                                                            </small>
                                                            :
                                                            null
                                                    }
                                                    {bathrooms ?
                                                        <small>
                                                            <FontAwesomeIcon icon={faToilet} className='mx-1 text-primary ' />
                                                            {bathrooms}
                                                            bt
                                                        </small> : null
                                                    }


                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </>
                            )
                        })
                    )
                }
            </Row>
        </Container>
    );
};

export default Projects;
