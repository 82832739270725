import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import routes from '../routes';
import Logo from '../Assets/logo.png'
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
const Header = () => {
    return (
        <Navbar bg="white" expand="lg" className='shadow-lg' sticky="top" className='prop-nax-header'>
            <Container>
                <Navbar.Brand as={Link} to="/">
                    <img src={Logo} className='logo' alt='logo' />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        {
                            React.Children.toArray(
                                routes.map(({ name, path, notInHeader }) => {
                                    if (notInHeader) {
                                        return;
                                    }
                                    return (
                                        <Nav.Link as={Link} to={path} className={`${window.location.pathname === path ? 'text-warning' : 'active-nav-url'}`}>{name}</Nav.Link>

                                    )
                                })
                            )
                        }
                        <Nav.Link>
                            <FontAwesomeIcon icon={faFacebook} />
                        </Nav.Link>
                        <Nav.Link>
                            <FontAwesomeIcon icon={faInstagram} />
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Header;
