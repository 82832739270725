import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import CustomLoader from '../CustomLoader';
import Pin from '../../Assets/location-pin.png'
const ContactMap = (props) => {
  const { location, google } = props
  const initialMapstate = {
    currentLocation: {
      lat: 1.3554481,
      lng: 103.881026,
    }
  }
  if (!location) {
    return <CustomLoader />
  }
  return (
    <>
      <Container className='my-4'>
        <Row className='mb-4 gmap-wrapper'>
          <Col>
            <Map
              google={google}
              className={'map'}
              zoom={10}
              initialCenter={initialMapstate?.currentLocation}
              zoomControl={true}
              disableDefaultUI={true}
              gestureHandling={"greedy"}
            >
              {
                location ?
                  <Marker
                    position={{ lat: location.latitude, lng: location.longitude }}
                    icon={{
                      url: Pin,
                      anchor: new google.maps.Point(42, 42),
                      scaledSize: new google.maps.Size(42, 42)
                    }}
                  />
                  : null
              }


            </Map>

          </Col>
        </Row>

      </Container>
    </>
  )

};

export default GoogleApiWrapper({
  apiKey: ('AIzaSyBv0qt6QTKqAGSynzrCQPy6gyLyEdE8HUY')
})(ContactMap);
