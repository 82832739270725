import React from 'react';
import { Spinner } from 'react-bootstrap';

const CustomLoader = () => {
    return (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
            <Spinner animation="border" role="status" style={{ width: '5rem', height: '5rem' }} variant={'primary'}>
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    )
};

export default CustomLoader;
