import React from 'react';

const Price = ({ min, max }) => {
  if (!min && !max) {
    return null
  }
  if (!min) {
    return (
      <small>
        <strong>
          {`$ ${max}`}
        </strong>
      </small>
    )
  }
  if (!max) {
    return (
      <small>
        <strong>
          {`$ ${min}`}
        </strong>
      </small>
    )
  }
  return (
    <small>
      <strong>
        {`$ ${min}/${max}`}
      </strong>
    </small>
  )
}

export default Price;
