import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Container, FormControl, InputGroup, Row } from 'react-bootstrap';
import AllProperties from '../Components/AllProperties';
import PropertiesMap from '../Components/PropertiesMap'
import useProperties from '../Utils/useProperties';
import { fillterConstanForBedrooms, fillterConstanForBathrooms } from '../Utils/FilterConstants'
const Home = () => {
    const [properties, setPoperties, filter, setFilter] = useProperties()
    return (
        <Container className='mt-3 home-page'>
            <div className="property-section">
                <Row>
                    <Col lg={5} sm={12}>
                        <Row className='search-prop'>
                            <InputGroup className="mb-3 prpnx-search">
                                <FormControl
                                    placeholder="Find Property"
                                    aria-label="Find Property"
                                    aria-describedby="basic-addon2"
                                    name="search"
                                    onChange={({ target: { name, value } }) => {
                                        setFilter({
                                            ...filter,
                                            [name]: value.replaceAll(',', '')
                                        })
                                    }}
                                />
                                <InputGroup.Text id="basic-addon2">
                                    <FontAwesomeIcon icon={faSearch} color="#fff" />
                                </InputGroup.Text>
                            </InputGroup>
                        </Row>
                        <div className="all-properties">
                            <Row className='gx-2'>
                                <Col lg={4} xs={6} className="my-2">
                                    <input type='text' name="min_price"
                                        value={filter?.min_price ? new Intl.NumberFormat('en-SG').format(filter?.min_price) : ''}
                                        onChange={({ target: { name, value } }) => {
                                            setFilter({
                                                ...filter,
                                                [name]: value.replaceAll(',', '')
                                            })
                                        }}
                                        placeholder='Min Price' className='form-control' />
                                </Col>
                                <Col lg={4} xs={6} className="my-2">
                                    <input type='text' name="max_price"
                                        value={filter?.max_price ? new Intl.NumberFormat('en-SG').format(filter?.max_price) : ''}

                                        onChange={({ target: { name, value } }) => {
                                            setFilter({
                                                ...filter,
                                                [name]: value.replaceAll(',', '')
                                            })
                                        }}
                                        placeholder='Max Price' className='form-control' />
                                </Col>
                                <Col lg={4} xs={6} className="my-2">
                                    <select
                                        name="project_type"
                                        onChange={({ target: { name, value } }) => {
                                            setFilter({
                                                ...filter,
                                                [name]: value
                                            })
                                        }}
                                        className="form-select">
                                        <option value="">Type</option>
                                        {[...new Set(properties.allProperties?.map(({ project_type }) => project_type))].map((type) => {

                                            return (
                                                <option value={type}>
                                                    {type}
                                                </option>
                                            )
                                        })}
                                    </select>
                                </Col>
                                <Col lg={4} xs={6} className="my-2">
                                    <select
                                        name="tenure"
                                        onChange={({ target: { name, value } }) => {
                                            setFilter({
                                                ...filter,
                                                [name]: value
                                            })
                                        }}
                                        className="form-select">
                                        <option value="">Tenure</option>
                                        {[...new Set(properties.allProperties?.map(({ tenure }) => tenure))].map((tenure) => {
                                            return (
                                                <option value={tenure}>{tenure}</option>
                                            )
                                        })}

                                    </select>

                                </Col>
                                <Col lg={4} xs={6} className="my-2">
                                    <select
                                        name="bedrooms"
                                        onChange={({ target: { name, value } }) => {
                                            setFilter({
                                                ...filter,
                                                [name]: value
                                            })
                                        }}
                                        className="form-select">
                                        <option value="">Bedrooms</option>
                                        {
                                            fillterConstanForBedrooms.map((bedroom) => {
                                                return (
                                                    <option bedroom={bedroom}>{bedroom}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </Col>
                                <Col lg={4} xs={6} className="my-2">
                                    <select
                                        name="bathrooms"
                                        onChange={({ target: { name, value } }) => {
                                            setFilter({
                                                ...filter,
                                                [name]: value
                                            })
                                        }}
                                        className="form-select">
                                        <option value="">bathrooms</option>
                                        {
                                            fillterConstanForBathrooms.map((bathroom) => {
                                                return (
                                                    <option bathroom={bathroom}>{bathroom}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </Col>
                            </Row>
                            {properties.filteredData ? <Row className='gx-2'>
                                <h6 className='nunitoFont text-warning mx-1' >
                                    {
                                        properties.filteredData.length === 0 || properties.allProperties.length === 0 ?
                                            'No Property found'
                                            :
                                            null
                                    }
                                </h6>
                            </Row> : null}
                            <AllProperties allProperties={properties.filteredData ?? properties.allProperties} />
                        </div>
                    </Col>
                    <Col lg={7} sm={12}>
                        <PropertiesMap extraClasses="prpnx-home-map" locations={properties.location} />
                    </Col>
                </Row>
            </div>
        </Container>
    )
};

export default Home;
