import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelopeSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import routes from '../routes';

const Footer = () => {
    return (
        <>
            <footer className="footer_one bg-dark mt-5 py-4">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 pr0 pl0">
                            <div className="footer_about_widget">
                                <h4>PropertyRoll</h4>
                                <p className='text-light'>
                                    <div className="row">
                                        <div className="col-10">
                                            We’re reimagining how you buy, sell and rent. It’s now easier to get into a place you love. So let’s do this, together.
                                        </div>
                                    </div>
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-2 col-lg-2 col-xl-2">
                            <div className="footer_qlink_widget">
                                <h4>Quick Links</h4>
                                <ul className="list-unstyled">
                                    {
                                        React.Children.toArray(
                                            routes.map(({ name, path, notInHeader }) => {
                                                if (notInHeader) {
                                                    return;
                                                }
                                                return (
                                                    <li>
                                                        <Link to={path}>{name}</Link>
                                                    </li>
                                                )
                                            }

                                            )
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div className="footer_contact_widget">
                                <h4>Contact Us</h4>
                                <ul className="list-unstyled">
                                    <li className='mb-2'>
                                        <FontAwesomeIcon icon={faEnvelopeSquare} color="#fff" className='mx-2' />
                                        <a href="mailto:enquiry@propertyroll.sg">
                                            enquiry@propertyroll.sg
                                        </a>
                                    </li>
                                    <li className='mb-2'>
                                        <FontAwesomeIcon icon={faWhatsapp} color="#fff" className='mx-2' />
                                        <a target="blank" href="http://wa.me/6582425588">+6582425588</a>
                                    </li>
                                    <li className='mb-2'>
                                        <FontAwesomeIcon icon={faWhatsapp} color="#fff" className='mx-2' />
                                        <a target="blank" href="http://wa.me/6586548658">+6586548658</a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="bg-dark text-light">
                <div className='container'>
                    <Row>
                        <Col lg={8} md={8} sm={12}>
                            <span>
                                &copy; {new Date().getFullYear()} Property Roll
                            </span>
                        </Col>
                        <Col lg={4} md={4} sm={12}>
                            <Link to='/privacy-policy' className='link'>Privacy Policy</Link>

                        </Col>
                    </Row>
                </div>

            </div>
        </>
    )
};
export default Footer;
