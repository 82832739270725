import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Badge, Col, Modal, Row } from 'react-bootstrap';
import PropertyImagesCarousal from '../PropertyImagesCarousal';

import Placeholder from '../../Assets/placeholder.jpg';

const PropertyModal = ({ show, handleClose }) => {
  return <div>
    <Modal show={show} onHide={handleClose} centered size="lg" className='prop-model'>
      <Modal.Body>
        <Row>
          <Col>
            <PropertyImagesCarousal images={show?.property_images?.length > 0 ? show.property_images : [Placeholder]} />
          </Col>
          <Col className='prop-modal-details'>
            <div className="d-flex justify-content-between align-items-center">
              <strong>Property Details</strong>
              <span>
                <FontAwesomeIcon icon={faTimes} onClick={handleClose} />
              </span>
            </div>
            <hr />
            <small>Facilities</small>
            <div>
              {React.Children.toArray(
                show?.facilities?.map((facility) => {
                  return (
                    <Badge className='mx-2 rounded-pill' bg="info">
                      {facility}
                    </Badge>
                  )
                })
              )}
            </div>
            <hr />
            <Row>
              <Col>
                <small> Project Name</small>
                <div>
                  <strong>
                    {show?.project_name}
                  </strong>
                </div>
              </Col>
              <Col>
                <small>Tenure</small>
                <div>
                  <strong>
                    {show?.tenure}
                  </strong>
                </div>
              </Col>
              <hr />
            </Row>
            <Row>
              <Col>
                <small> Launch Data</small>
                <div>
                  <strong>
                    {show?.launch_date}
                  </strong>
                </div>
              </Col>
              <Col>
                <small>Project Type</small>
                <div>
                  <strong>
                    {show?.project_type}
                  </strong>
                </div>
              </Col>
              <hr />
            </Row>
            <Row>
              <Col>
                <small> Disctrict</small>
                <div>
                  <strong>
                    {show?.district}
                  </strong>
                </div>
              </Col>
              <Col>
                <small>Region</small>
                <div>
                  <strong>
                    {show?.region}
                  </strong>
                </div>
              </Col>
              <hr />
            </Row>
            <Row>
              <div>Addresss</div>
              <div>{show?.address}</div>
            </Row>
            <hr />
            <Row>
              <Col>
                <div>Developer</div>
                <div>{show?.developer?.map((dev) => <small><strong>{dev}</strong></small>)}</div>
              </Col>
              <Col>
                <div>Architech</div>
                <strong>{show?.architect}</strong>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <div>Bathroom(s)</div>
                <strong>
                  {show?.bathrooms}
                </strong>
              </Col>
              <Col>
                <div>Land Size</div>
                <strong>
                  {new Intl.NumberFormat('en-SG').format(show?.land_size)} sqft.
                </strong>
              </Col>
            </Row>

          </Col>
        </Row>
      </Modal.Body>

    </Modal>
  </div>;
};

export default PropertyModal;
