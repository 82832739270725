import { faEnvelopeSquare, faMapMarkerAlt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import GlobalCards from '../GlobalCards/Index';

const ContactInfo = () => {
  return (
    <Container className='my-5 py-5'>
      <Row>
        <Col lg={4} md={4} sm={12}>
          <GlobalCards iconname={faEnvelopeSquare} title="Email Address" description={"enquiry@propertyroll.sg"} />
        </Col>
        <Col lg={4} md={4} sm={12}>
          <GlobalCards iconname={faPhoneAlt} title="Phone Number" description={"6586548658"} description2={"6582425588"} />
        </Col>
        <Col lg={4} md={4} sm={12}>
          <GlobalCards iconname={faMapMarkerAlt} title="18/A, New Born Town Hall" description={"New York, US"} />
        </Col>
      </Row>
    </Container>
  )
};

export default ContactInfo;
