import { faFunnelDollar, faHouseUser, faPeopleCarry } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import GlobalCards from '../GlobalCards/Index';

const WhyChooseUs = () => {
  return (
    <Container className='mb-5'>
      <div className="text-center whycooseuse">
        <h2 className="mt-5 mb-2 nunitoFont">
          Why Choose Us
        </h2>
        <p>
          We provide full service at every step.
        </p>
      </div>
      <Row>
        <Col lg={4} md={4} sm={12}>
          <GlobalCards iconname={faPeopleCarry} title="Trusted By Thousands" description={"Aliquam dictum elit vitae mauris facilisis at dictum urna dignissim donec vel lectus vel felis."} />
        </Col>
        <Col lg={4} md={4} sm={12}>
          <GlobalCards iconname={faHouseUser} title="Wide Renge Of Properties" description={"Aliquam dictum elit vitae mauris facilisis at dictum urna dignissim donec vel lectus vel felis."} />
        </Col>
        <Col lg={4} md={4} sm={12}>
          <GlobalCards iconname={faFunnelDollar} title="Financing Made Easy" description={"Aliquam dictum elit vitae mauris facilisis at dictum urna dignissim donec vel lectus vel felis.."} />
        </Col>
      </Row>
    </Container>
  );
};

export default WhyChooseUs;
